import React, { useState } from "react";
import { SiDatabricks } from "react-icons/si";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./NavbarStyles.css";
import stylotech from "../../assets/stylotech.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleNav = () => setNav(!nav);
   
  const closeMobileMenu = () => setNav(false);
 
  return (
    <div name="top" className="navbar">
      <div className="container">
        <div className="logo">
          <Link to="/"><img src={stylotech} alt="" /></Link>
          <Link to="/"><h2>StyloTech</h2></Link>
        </div>

        <ul className={nav ? "nav-menu active" : "nav-menu"}>
          <li>
            <Link to="/" onClick={closeMobileMenu}>Home</Link>
          </li>
          <li>
            <Link to="/services" onClick={closeMobileMenu}>Services</Link>{" "}
          </li>
          <li>
            <Link to="/about-us" onClick={closeMobileMenu}>About Us</Link>
          </li>
          <li className="drop-hover">
             <Link to="#">We Serve</Link>
             <ul className="dropdown assist-drop">
                 <li><Link to="/parents" onClick={closeMobileMenu}>Parents</Link></li>
                 <li><Link to="/schools" onClick={closeMobileMenu}>Schools</Link></li>
                 <li><Link to="/institutions" onClick={closeMobileMenu}>Institutions</Link></li>
                 <li><Link to="/goverment" onClick={closeMobileMenu}>Government</Link></li>
                 <li><Link to="/company" onClick={closeMobileMenu}>Company / Organization / Community</Link></li>
                 <li><Link to="/citizen" onClick={closeMobileMenu}>Citizen</Link></li>
                 <li><Link to="/buisness" onClick={closeMobileMenu}>Small & Medium Business</Link></li>
             </ul>
          </li>
          <li className="drop-hover">
             <Link to="/">Programs</Link>
             <ul className="dropdown">
                 <li><Link to="/cyber-safe-for-kids">Cyber Safe For Kids</Link></li>
                 <li><Link to="/cyber-safe-for-parents">Cyber Safe For Parents</Link></li>
                 <li><Link to="/cyber-safe-for-teachers">Cyber Safe For Teachers</Link></li>
                 <li><Link to="/cyber-safe-for-corporates">Cyber Safe For Corporates</Link></li>
                 <li><Link to="/cyber-safe-for-goverments">Cyber Safe For Government</Link></li>
                 <li><Link to="/cyber-safe-for-citizen">Cyber Safe For Citizens</Link></li>
                 <li><Link to="/cyber-safe-fot-SMB">Cyber Safe For SMB</Link></li>
             </ul>
          </li>
          <button>
            <a href="https://wa.me/918668431882" target="_blank" rel="noreferrer">
              Contact
            </a>
          </button>
        </ul>
        <div className="hamburger" onClick={handleNav}>
          {!nav ? <FaBars className="icon" /> : <FaTimes className="icon" />}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
