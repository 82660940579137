/** @format */

import React from "react";
import "./WeAssist.css";

const WeAssist = (props) => {
  return (
    <div className="we-assist">
      <div className="we-flex">
        <div className="we-card">
          <div>
            <img src={props.AssistImg} alt="" />
          </div>
          <div>
            <h2>{props.AssistH2}</h2>
            <p>{props.AssistPara}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeAssist;
