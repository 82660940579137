import React from 'react'
import WeAssist from '../../components/WeAssist/WeAssist'

const AssistCitizenShip = () => {
  return (
      <>
          <WeAssist
        AssistImg="https://images.unsplash.com/photo-1555725305-0406b7607be0?crop=entropy&cs=tinysrgb&fm=jpg&ixlib=rb-1.2.1&q=80&raw_url=true&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=872"
              
        AssistH2="Citizen"
              
        AssistPara="The internet is an incredible tool. It provides an opportunity to communicate, learn, play and be entertained by content from around the world. It's vibrant, incredibly varied and offers information on any topic at a click of the mouse. While the internet provides many positives there are also risks. Cyberbullying, inappropriate contact, identity theft, scams and exposure to adult content can make the internet a risky place for children. The risks exist for all children who are only starting to use a computer, to more experienced teens, and also adults. Every single person in the world is connected to internet. Hence it is important to aware people about the darker side of internet and how crimes can happen. 'Cybersafe' organizes awareness programs which are beneficial for the citizens while they are online."
      />
    </>
  )
}

export default AssistCitizenShip