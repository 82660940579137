import React from 'react'
import './citizen.css'
import { citizenData } from '../data/data';

const Citizen = () => {
  return (
    <div>
      <div className='citizen-assist'></div>
      <div className="citizen">
        <div className="citizen_container">
        {citizenData.map((item) => (
          <div className="citizen-card">
            <h3>{item.citizenHead}</h3>
            <p>{item.citizenPara}</p>
          </div>
        ))}
        </div>
    </div>
    </div>
  )
}

export default Citizen