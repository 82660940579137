/** @format */

import React from "react";
import "./smb.css";
import { SmbData } from "../data/data";

const Smb = () => {
  return (
    <div>
      <div className="smb_bg"></div>
      <div className="smb">
        <div className="smb_container">
          <div className="smb_title">
            <h2>Cyber Safety for SMB</h2>
            <p>
              The Internet allows businesses of all sizes and from any location
              to reach new and larger markets and provides opportunities to work
              more efficiently by using computer-based tools. Whether a business
              is thinking of adopting cloud computing or just using email and
              maintaining a website, cybersecurity should be a part of the plan.
              Theft of digital information has become the most commonly reported
              fraud, surpassing physical theft. Every business that uses the
              Internet is responsible for creating a culture of security that
              will enhance business and consumer confidence.
            </p>
          </div>
          <div className="smb_title">
            <h2>10 Cyber Security Tips for Small Business</h2>
            <p>
            Broadband and information technology are powerful factors in small businesses reaching new markets and increasing productivity and efficiency. However, businesses need a cybersecurity strategy to protect their own business, their customers, and their data from growing cybersecurity threats.
            </p>
          </div>
          {SmbData.map((item) => (
            <div className="smb_card">
              <h3>
                {item.smbQue} <i class="fa-solid fa-angle-down"></i>
              </h3>
              <p>{item.smbAns}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Smb;
