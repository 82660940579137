/** @format */

import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import Citizen from "./pages/Citizen";
import Smb from "./pages/Smb";
import AssistCitizen from "./pages/WeAssistPage/AssistCitizenShip";

const Home = lazy(() => import("./routes/Home"));
const RecoveryPage = lazy(() => import("./routes/RecoveryPage"));
const CloudPage = lazy(() => import("./routes/CloudPage"));
const WeAssistPage = lazy(() => import("./routes/WeAssistPage"));
const Kids = lazy(() => import("./pages/Kids"));
const Parents = lazy(() => import("./pages/Parents"));
const Teachers = lazy(() => import("./pages/Teachers"));
const Corporates = lazy(() => import("./pages/Corporates"));
const Goverment = lazy(() => import("./pages/Goverment"));
const AssistParents = lazy(() => import("./pages/WeAssistPage/AssistParents"))
const AssistSchool = lazy(() => import("./pages/WeAssistPage/AssistSchool"));
const AssistInstitutions = lazy(() => import("./pages/WeAssistPage/AssistInstitutions"));
const AssistGoverment = lazy(() => import("./pages/WeAssistPage/AssistGoverment"));
const AssistCompany = lazy(() => import("./pages/WeAssistPage/AssistCompany"));
const AssistCitizenShip = lazy(() => import("./pages/WeAssistPage/AssistCitizenShip"));
const AssistBuisness = lazy(() => import("./pages/WeAssistPage/AssistBuisness"));

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Suspense fallback={<div>Loading.....</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<RecoveryPage />} />
            <Route path="/about-us" element={<CloudPage />} />
            <Route path="/we-assist" element={<WeAssistPage />} />
            <Route path="/cyber-safe-for-kids" element={<Kids />} />
            <Route path="/cyber-safe-for-parents" element={<Parents />} />
            <Route path="/cyber-safe-for-teachers" element={<Teachers />} />
            <Route path="/cyber-safe-for-corporates" element={<Corporates />} />
            <Route path="/cyber-safe-for-goverments" element={<Goverment />} />
            <Route path="/parents" element={<AssistParents />}/>
            <Route path="/schools" element={<AssistSchool />}/>
            <Route path="/institutions" element={<AssistInstitutions />}/>
            <Route path="/goverment" element={<AssistGoverment />}/>
            <Route path="/company" element={<AssistCompany />}/>
            <Route path="/buisness" element={<AssistBuisness />}/>
            <Route path="/citizen" element={<AssistCitizenShip />} />
            <Route path="/cyber-safe-for-citizen" element={<Citizen />} />
            <Route path="/cyber-safe-fot-SMB" element={<Smb />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
