export const citizenData = [
    {
        id: 1,
        citizenHead: "BE A RESPONSIBLE CITIZEN",
        citizenPara: "If you use the Internet, you're a citizen of a global community-a cyber citizen. Just like being a citizen of your local community, being a cyber citizen has responsibilities. Use the Internet to share knowledge that makes people's lives better. Keep safe, use good manners and respect the laws."
    },
    {
        id: 2,
        citizenHead: "USE ANTIVIRUS SOFTWARE:",
        citizenPara: "A computer virus is a program that can invade your computer and d mage or information. Anti-virus software is designed to protect you and yourcomputer against known viruses. But with new viruses emerging daily, anti-virus programs need to be updated regularly. Check with the web site of youranti-virus software company to see some sampledescriptions of viruses and to get regular updates foryour software. Stop viruses in their tracks!"
    },
    {
        id: 3,
        citizenHead: "DO NOT OPEN EMAIL FROM UNKNOWN SOURCE:",
        citizenPara: "Delete email from unknown sources. Watch out for files attached to e-mails, particularly those with an exe extension-even if people you know sent them to you. Some files transport and distribute viruses and other programs that can permanently destroy files and damage computers and Web sites. Do not forward email if you are not completely sure that any attached files are safe."
    },
    {
        id: 4,
        citizenHead: "USE HARD-TO-GUESS PASSWORDS AND KEEP THEM PRIVATE:",
        citizenPara: "Do not write passwords down on small pieces of paper taped to your computer. You would be surprised how many people are sloppy about keeping their passwords private. Passwords that are easy to-guess are a bad choice. In other words, if your name is Dan do not make your password Dan. Change your passwords regularly and don't give your passwords to anyone! Tell your family that combinations of letters, numbers and symbols are harder to crack than just words."
    },
    {
        id: 5,
        citizenHead: "Protect computer from firewalls:",
        citizenPara: "Install firewalls for your family-it is not difficult. Afirewall helps prevent hackers from breaking into your computer or the computers that belong to your family. Firewalls help prevent thieves from stealing and using private information including your phone number and credit card numbers, which may be stored on a family computer."
    },
    {
        id: 6,
        citizenHead: "Do not share access to your computers with strangers. Learn about file sharing risks.",
        citizenPara: "Your computer operating system may allow other computers on a network, including the Internet, to access the hard-drive of your computer in order to share files. This ability to share files can be used to infect your computer with a virus or look at the files on your computer if you do not pay close attention. Check your operating system and other program help files to learn how to disable file sharing. Do not share access to your computer with strangers"
    },
    {
        id: 7,
        citizenHead: "Disconnect from the Internet when not in use.",
        citizenPara: "The Internet is a two-way road. You get information and also send information. Turning off the Internet makes sure that someone else on the Internet can't enter your computer and cause harm. Disconnecting your computer from the Internet when you are not online lessens the chance that someone will be able to access your computer."
    },
    {
        id: 8,
        citizenHead: "Regularly download security protection",
        citizenPara: "update patches. Security flaws are regularly found in operating systems and application software. Companies that make software release quick fixes called patches that you should install to correct the latest software flaw. It is a good idea to check for security updates on the publisher's Web site for all the software you own."
    },
    {
        id: 9,
        citizenHead: "Help your family to check computer",
        citizenPara: "security on a regular basis. Evaluate computer security at least twice a year. o help remember, do it when you change the clocks for daylight-savings time! Check for all of the items listed previously."
    },
    {
        id: 10,
        citizenHead: "Back-up your computer regularly.",
        citizenPara: "Help your family back up all household computers onto external media such as CD's or diskettes."
    }
];


export const SmbData = [
    {
        id: 1,
        smbQue: "1. Train employees in security principles",
        smbAns:"Establish basic security practices and policies for employees, such as requiring strong passwords, and establish appropriate Internet use guidelines that detail penalties for violating company cybersecurity policies. Establish rules of behavior describing how to handle and protect customer information and other vital data."
    },
    {
        id: 2,
        smbQue: "2. Protect information, computers and networks from cyber attacks",
        smbAns:"Keep clean machines: having the latest security software, web browser, and operating system are the best defenses against viruses, malware, and other online threats. Set antivirus software to run a scan after each update. Install other key software updates as soon as they are available."
    },
    {
        id: 3,
        smbQue: "3. Provide firewall security for your Internet connection",
        smbAns:"A firewall is a set of related programs that prevent outsiders from accessing data on a private network. Make sure the operating system’s firewall is enabled or install free firewall software available online. If employees work from home, ensure that their home system(s) are protected by a firewall."
    },
    {
        id: 4,
        smbQue: "4. Create a mobile device action plan",
        smbAns:"Mobile devices can create significant security and management challenges, especially if they hold confidential information or can access the corporate network. Require users to password protect their devices, encrypt their data, and install security apps to prevent criminals from stealing information while the phone is on public networks. Be sure to set reporting procedures for lost or stolen equipment."
    },
    {
        id: 5,
        smbQue: "5. Make backup copies of important business data and information",
        smbAns:"Regularly backup the data on all computers. Critical data includes word processing documents, electronic spreadsheets, databases, financial files, human resources files, and accounts receivable/payable files. Backup data automatically if possible, or at least weekly and store the copies either offsite or in the cloud."
    },
    {
        id: 6,
        smbQue: "6. Control physical access to your computers and create user accounts for each employee",
        smbAns:"Prevent access or use of business computers by unauthorized individuals. Laptops can be particularly easy targets for theft or can be lost, so lock them up when unattended. Make sure a separate user account is created for each employee and require strong passwords. Administrative privileges should only be given to trusted IT staff and key personnel."
    },
    {
        id: 7,
        smbQue: "7. Secure your Wi-Fi networks",
        smbAns:"If you have a Wi-Fi network for your workplace, make sure it is secure, encrypted, and hidden. To hide your Wi-Fi network, set up your wireless access point or router so it does not broadcast the network name, known as the Service Set Identifier (SSID). Password protect access to the router."
    },
    {
        id: 8,
        smbQue: "8. Employ best practices on payment cards",
        smbAns:"Work with banks or processors to ensure the most trusted and validated tools and anti-fraud services are being used. You may also have additional security obligations pursuant to agreements with your bank or processor. Isolate payment systems from other, less secure programs and don’t use the same computer to process payments and surf the Internet."
    },
    {
        id: 9,
        smbQue: "9. Limit employee access to data and information, limit authority to install software",
        smbAns:"Do not provide any one employee with access to all data systems. Employees should only be given access to the specific data systems that they need for their jobs, and should not be able to install any software without permission."
    },
    {
        id: 10,
        smbQue: "10. Passwords and authentication",
        smbAns:"Require employees to use unique passwords and change passwords every three months. Consider implementing multi-factor authentication that requires additional information beyond a password to gain entry. Check with your vendors that handle sensitive data, especially financial institutions, to see if they offer multi-factor authentication for your account."
    },
]