import React from 'react'
import './PageNotFound.css'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
      <div className='pageNotFound'>
          <h1>You Are Entering The Wrong URL Redirect To <Link to="/">HOME</Link></h1>
    </div>
  )
}

export default PageNotFound